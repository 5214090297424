import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs/internal/Observable";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private router: Router,
    private Location: Location,
    private route: ActivatedRoute
  ) {
    //check for proxy download file url
    const url = this.Location.path();
    console.log(url);
    this.extractIdFromUrl(url);
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (localStorage.getItem("isLoggedin")) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }

  canActivateChild(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = localStorage.getItem("isLoggedin");
    if (user) {
      const { roles } = route.data;
      const userRole = Number(localStorage.getItem("userRole"));
      let roleUser;
      if (userRole === 0) {
        roleUser = "Service Owner";
      } else if (userRole === 1) {
        roleUser = "Factory Lead";
      } else if (userRole === 2) {
        roleUser = "Admin";
      } else if (userRole === 3) {
        roleUser = "Global Factory Lead";
      } else if (userRole === 4) {
        roleUser = "Service Portfolio Owner";
      } else {
        roleUser = "";
      }
      console.log("roles", roles);
      console.log("roleUser", roleUser);
      if (roles != undefined) {
        var check = roles.includes(roleUser);
        const isTempUser = localStorage.getItem("tempUser")
        console.log("check", check);
        // console.log("operator",roles && roles != userRole )
        if (!check && !isTempUser) {
          console.log("userRoleDoesntMatch");
          // role not authorized so redirect to Acces Denied Page
          this.router.navigate(["/layout/unauthorized"]);
          return false;
        }
      }
      // authorized so return true
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }

  extractIdFromUrl(url: string) {
    const decodedUrl = decodeURIComponent(url);

    if (decodedUrl.indexOf("download/") !== -1) {
      const parts = decodedUrl.split("download/");
      console.log(parts);
      if (parts) {
        const lastPart = parts[parts.length - 1];
        localStorage.setItem("docId", lastPart);
      }
    }

    if (decodedUrl.indexOf("feedback?token") !== -1) {
      const parts = decodedUrl.split("feedback?token=");
      console.log(parts);
      if (parts) {
        const lastPart = parts[parts.length - 1];
        localStorage.setItem("tempToken", lastPart);
        const newUrl = decodedUrl.split("?")[0]; // Keeps the URL up to the "?"
        window.history.replaceState({}, document.title, newUrl);
      }
    }

  }
}
