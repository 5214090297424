import { FormGroup, FormBuilder, Validators, NgForm } from "@angular/forms";
import { Component, ElementRef, OnInit } from "@angular/core";
import { Regex } from "../shared/utils/regex";
import { LoginService } from "./service/login.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import * as CryptoJS from "crypto-js";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  LoginForm!: FormGroup;
  key = "5623453287217387";
  iv = "5623453287217387";

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private toastr: ToastrService,
    public router: Router,
    private SpinnerService: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.LoginForm = this.formBuilder.group({
      username: [
        "",
        [
          Validators.required,
          Validators.maxLength(Regex.pattern.usernameEmailmaxLength),
          Validators.minLength(Regex.pattern.usernameminLength),
          Validators.pattern(Regex.pattern.EMAILUSERNAMEREGEX),
        ],
      ],
      password: [
        "",
        [
          Validators.required,
          Validators.maxLength(Regex.pattern.PassmaxLength),
          Validators.minLength(Regex.pattern.PassminLength),
          Validators.pattern(Regex.pattern.Password),
        ],
      ],
    });

    // get download url
    this.route.queryParams.subscribe((params) => {
      // doc download
      const redirectUrl = params["redirect"];
      if (redirectUrl) {
        const docId = this.extractIdFromUrl(redirectUrl);
        localStorage.setItem("docId", docId);
        console.log(docId);
      }
    });

    const tempToken = localStorage.getItem("tempToken");
    if (tempToken) {
      localStorage.setItem("token", tempToken);
      localStorage.setItem("tempUser", "true");
      localStorage.setItem("isLoggedin", "true");
      this.router.navigate(["/layout/feedback"]);
    }
  }

  encryptUsingAES256(text): any {
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(text),
      this.key,
      {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    return encrypted.toString();
  }

  login() {
    this.SpinnerService.show();
    // var inputObj ={
    //   username: this.LoginForm.value.username,
    //   password: this.encryptUsingAES256(this.LoginForm.value.password)
    // }
    const input = this.LoginForm.value.username;
    const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);
    const inputObj = {
      [isEmail ? "emailaddress" : "username"]: input,
      password: this.encryptUsingAES256(this.LoginForm.value.password),
    };
    this.loginService.login(inputObj).subscribe({
      next: (result: any) => {
        this.SpinnerService.hide();
        if (result && result.status == 200) {
          this.toastr.success(result.message);
          localStorage.setItem("token", result.user.token);
          localStorage.setItem("email", result.user.emailaddress);
          localStorage.setItem("username", result.user.username);
          localStorage.setItem("userRole", result.user.userrole);
          localStorage.setItem("isLoggedin", "true");
          localStorage.setItem("userId", result.user?.id);
          if (result.user.userrole === "0") {
            this.router.navigate(["/layout/asset-management"]);
          }
          if (result.user.userrole === "1") {
            if (result?.user?.autogeneratedpasswordFL === "0") {
              this.router.navigate(["/layout/change-password"]);
            } else {
              this.router.navigate(["/layout/asset-management"]);
            }
          } else if (
            result.user.userrole === "2" ||
            result.user.userrole === "3" ||
            result.user.userrole === "4"
          ) {
            this.router.navigate(["/layout/user-management"]);
          }
        } else {
          console.log("res", result);
        }
      },
      error: (error) => {
        console.log("error", error);
        this.SpinnerService.hide();
        if (error && error.error && error.error.message) {
          this.toastr.error(error.error.message);
        } else if (error && error.message) {
          this.toastr.error(error.message);
        } else {
          this.toastr.error("Something went Wrong");
        }
      },
    });
  }

  extractIdFromUrl(url: string): string {
    const parts = url.split("download/");
    const lastPart = parts[parts.length - 1];
    return lastPart;
  }
}
