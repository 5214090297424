import { Injectable } from '@angular/core';
import { BaseUrl } from '../utils/base-url';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private baseUrl: BaseUrl) {}

  checkFileId(id: any, userRole: any) {
    if (userRole === '1' || userRole === '0' || userRole === '2') {
      const fileUrl = this.baseUrl.DownloadFile + id;
      window.open(fileUrl, '_blank');
      localStorage.removeItem('docId');
    } else {
      localStorage.removeItem('docId');
    }
  }
}
